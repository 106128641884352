import { phoneFilter } from '@/shared/filters/ToPhoneFormatted' 
import TestSituations from '@/shared/enums/testSituation'

export default class WaitingListAdditional {
  constructor({
    localTrainingName,
    name,
    responsible,
    district,
    rg,
    cpf,
    observation,
    hasDeficiency,
    registrationDate,
    localTrainingsModalities,
    tests,
    contacts
  } = {}) {
    this.localTrainingName = localTrainingName,
    this.name = name,
    this.responsible = responsible,
    this.district = district,
    this.rg = rg,
    this.cpf = cpf,
    this.observation = observation,
    this.hasDeficiency = hasDeficiency ? 1 : 0,
    this.registrationDate = registrationDate,
    this.localsTrainingModalities = localTrainingsModalities ? localTrainingsModalities : [],
    this.tests = tests ? this.mapTests(tests) : [],
    this.contacts = contacts ? this.mapContacts(contacts) : []
  }
  
  mapContacts (contacts) {
    if (!contacts) return

    const contactsMapped = contacts.map(element => {

      const contactObj = {
        name: element.name,
        phones: phoneFilter(element.phone1)
      }

      if(element.phone2)
        contactObj.phones += ` / ${phoneFilter(element.phone2)}`

      if(element.phone3)
        contactObj.phones += ` / ${phoneFilter(element.phone3)}`

      return contactObj
    })

    return contactsMapped
  }

  mapTests (tests) {
    if (!tests) return

    const testsMapped = tests.map(element => {

      const testObj = {
        className: element.className,
        result: this.getTestSituationName(element.result),
        date: element.date
      }

      return testObj
    })

    return testsMapped
  }

  getTestSituationName (result) {
    const testSituations = Object.values(TestSituations)

    const testSituation = testSituations.find(situation => situation.id == result)

    if(!testSituation)
      return

    return testSituation.name
  }
}