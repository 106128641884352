import WaitingListHistoryCallRepository from '@/shared/http/repositories/socialProject/waitinglist-historycall'
import WaitingListTestRepository from '@/shared/http/repositories/socialProject/waitinglist-test'
import ModalityLocalTrainingRepository from '@/shared/http/repositories/socialProject/modalitylocaltraining'
import DeficiencyTypeRepository from '@/shared/http/repositories/socialProject/deficiency-type'
import WaitingListRepository from '@/shared/http/repositories/socialProject/waitinglist'
import LocalTrainingRepository from '@/shared/http/repositories/socialProject/localtraining'
import WaitingListDeficiencyRepository from '@/shared/http/repositories/socialProject/waitinglist-deficiency'
import ModalityRepository from '@/shared/http/repositories/socialProject/modality'
import ClassRepository from '@/shared/http/repositories/socialProject/class'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import Pagination from '@/components/layout/Pagination/pagination.vue'
import Search from '@/components/layout/Search/search.vue'
import fab from 'vue-fab'
import WaitingListList from '@/shared/models/waitinglist-list'
import WaitingListTest from '@/shared/models/waitinglist-test'
import WaitingListAdditional from '@/shared/models/waitinglist-additional'
import WaitingListCriteria from '@/shared/models/criteria/waitinglistcriteria'
import moment from 'moment'
import SchoolTerms from '@/shared/enums/schoolTerms'
import TestSituation from '@/shared/enums/testSituation'
import Roles from '@/shared/mixins/hasRoles'
import GuidUtils from '@/shared/utils/guid'
import { dateFilter } from '@/shared/filters/ToDate'
import { dateFormattedFilter } from '@/shared/filters/ToDateFormatted'
import { IsSuccessCode } from '@/shared/utils/API'
import { loading, toast } from '@/shared/utils/UIHelper'
import { phoneFilter } from '@/shared/filters/ToPhoneFormatted'
import WaitingListHistoryCall from '../../shared/models/waitinglist-historyCall'
import { mapGetters } from 'vuex'
import { globalGetters as AuthGetters } from '@/store/modules/auth/getters'
import CidRepository from '@/shared/http/repositories/socialProject/cid'
import download from 'downloadjs'

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb,
    [Pagination.name]: Pagination,
    [Search.name]: Search,
    fab
  },

  mixins: [Roles],

  data: () => ({
    waitinglists: [],
    waitinglistAdditionalInformation: {},
    localTrainings: [],
    modalities: [],
    classes: [],
    dataHistory: [],
    modalityLocalTraining: [],
    childrenSelected: [],
    deficienciesType: [],
    waitingListTest: new WaitingListTest(),
    criteria: new WaitingListCriteria(),
    schoolTerms: Object.values(SchoolTerms),
    testSituations: Object.values(TestSituation),
    waitingListHistoryCall: new WaitingListHistoryCall(),
    emptyTextHistory: 'Não há registro de contato telefônico',
    emptyText: 'Realize uma pesquisa utilizando os campos acima',
    showDrawerAdditionalInformation: false,
    showModalDeleteWaitingList: false,
    labelSearch: 'Lista de Espera',
    showModalWaitingListCreateTest: false,
    showModalHistoryWaitingList: false,
    showModalDeleteHistoryWaitingList: false,
    localTrainingSelected: null,
    modalitySelected: null,
    waitingListSelected: null,
    schoolTerm: null,
    classe: null,
    totalWaintingListChildren: 0,
    cidCode: null,
    deficiencyTypeName: null,
    cidDescription: null,
    showTextAlert: false,
    allCidCode: null,
    fieldsTests: [
      {
        key: 'className',
        label: 'Turma',
        class: 'left-header-border text-center'
      },
      {
        key: 'date',
        label: 'TESTE MARCADO EM',
        class: 'middle-header text-center',
        formatter: dateFormattedFilter
      },
      {
        key: 'result',
        label: 'Situação',
        class: 'right-header-border text-center'
      }
    ],
    fieldsContacts: [
      {
        key: 'name',
        label: 'Responsável',
        class: 'left-header-border text-center'
      },
      {
        key: 'phones',
        label: 'Telefones',
        class: 'right-header-border text-center'
      }
    ],
    fieldsLocalModalities: [
      {
        key: 'localTrainingName',
        label: 'Local',
        class: 'left-header-border text-center'
      },
      {
        key: 'modalityName',
        label: 'Modalidade',
        class: 'right-header-border text-center'
      }
    ],
    fields: [
      {
        key: 'name',
        label: 'CRIANÇA',
        class: 'left-header-border text-center',
        sortable: true
      },
      {
        key: 'dateLastContactTest',
        label: 'TESTE MARCADO EM',
        class: 'middle-header text-center',
        formatter: dateFormattedFilter
      },
      {
        key: 'contact.name',
        label: 'RESPONSÁVEL',
        class: 'middle-header text-center'
      },
      {
        key: 'birthDate',
        label: 'DATA DE NASCIMENTO',
        class: 'middle-header text-center ',
        formatter: dateFormattedFilter
      },
      {
        key: 'contact',
        label: 'TELEFONE',
        class: 'middle-header text-center',
        formatter: 'getContactPhones'
      },
      {
        key: 'schoolTerm',
        label: 'PERÍODO ESCOLAR',
        class: 'middle-header text-center',
        formatter: 'getSchoolTermName'
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ],
    fieldsHistory: [
      {
        key: 'historyDate',
        label: 'DATA DO CONTATO',
        class: 'left-header-border text-center',
        formatter: dateFormattedFilter,
        sortable: true
      },
      {
        key: 'messageOwner',
        label: 'AUTOR',
        class: 'middle-header text-center'
      },
      {
        key: 'callText',
        label: 'RELATÓRIO',
        class: 'middle-header text-center'
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ],
    pagination: {
      count: 1,
      limit: 10,
      index: 1
    }
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: "Dashboard",
            href: this.$router.resolve({ name: "Dashboard" }).href
          },
          {
            text: "Listagem de Espera",
            href: this.$router.resolve({ name: "NewChildren" }).href
          }
        ]
      }
    },
    ...mapGetters({
      loggedInUser: AuthGetters.LOGGED_IN_USER
    })
  },

  created() {
    this.dateContact = this.getCurrentDate()
    this.getAllLocalTraining()
    this.getAllModality()
    this.loadModalityLocalTraining()
    this.getAllDeficiencyType()
  },

  methods: {
    onCheckExistHistoryCall(item, type) {
      if (!item || type !== 'row') return
      if (item.historyCall != null) {
        return 'table-warning'
      }
    },

    onShowModalHistory(item) {
      this.childrenSelected = item
      this.waitingListHistoryCall.waitingListId = item.id
      this.getAllHistoryByWaitingListId()
      this.showModalHistoryWaitingList = true
    },

    closeModalHistory() {
      this.showModalHistoryWaitingList = false
      this.waitingListHistoryCall = new WaitingListHistoryCall()
      this.childrenSelected = []
    },

    closeModalDeleteHistory() {
      this.showModalDeleteHistoryWaitingList = false
      this.waitingListHistoryCall.id = GuidUtils.emptyGuid
    },

    onDeleteHistoryCall(item) {
      this.showModalDeleteHistoryWaitingList = true
      this.waitingListHistoryCall.id = item.id
    },

    getSchoolTermName (schoolTermId) {
      const schoolTerm = this.schoolTerms.find(term => term.id == schoolTermId)

      if(!schoolTerm)
        return

      return schoolTerm.name
    },

    getContactPhones (contact) {
      if (!contact) return

      let phones = phoneFilter(contact.phone1)

      if(contact.phone2)
        phones += ` / ${phoneFilter(contact.phone2)}`

      if(contact.phone3)
        phones += ` / ${phoneFilter(contact.phone3)}`

      return phones
    },

    getTestSituationName (result) {
      const testSituation = this.testSituations.find(test => test.id == result)

      if(!testSituation)
        return

      return testSituation.name
    },

    selectRow(id) {
      this.showModalDeleteWaitingList = true
      this.id = id
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },

    async onShowModalCreateTest(waitingList) {
      await this.clearTest()

      if(this.waitingListSelected){
        this.waitingListTest.waitingListId = this.waitingListSelected.id
        this.showModalWaitingListCreateTest = true
        return
      }

      this.showModalWaitingListCreateTest = true
      this.waitingListTest.waitingListId = waitingList.id
      this.waitingListTest.childName = waitingList.name
    },

    onDelete() {
      loading.push()
      WaitingListRepository.Delete(this.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.getAll()
          this.showModalDeleteWaitingList = false
          toast.success('Criança excluída com sucesso!', 'EXCLUSÃO')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir modalidade', 'ERRO')
        })
    },

    getAdditionalInformation(waitinglistId){
      loading.push()
      WaitingListRepository.GetAdditionalInformation(waitinglistId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.waitinglistAdditionalInformation = new WaitingListAdditional(res.data.data)

          if (this.waitinglistAdditionalInformation.hasDeficiency == 1) {
            this.getDeficiencyId(waitinglistId)
          }

          this.deficiencyTypeName = null
          this.cidCode = null
          this.cidDescription = null

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar criança', 'ERRO')
        })
    },

    downloadExcel() {
      loading.push()
      WaitingListRepository.ExportExcel(this.criteria)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          if(res.data.data === null) {
            toast.warning('Esta consulta não tem registros e não irá gerar planilha')
            loading.pop()
            return Promise.resolve()
          }

          const file = res.data.data
          const base64 = `data:${file.contentType};base64,${file.base64}`
          download(base64, file.alias, file.contentType)
          
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao exportar excel', 'ERRO')
        })
    },

    getAllCidCode (cidCode) {
      if (cidCode.length < 2) return

      loading.push()
      CidRepository.GetAllCidCode(cidCode)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          const cidCodes = res.data.data
          const onlyCidCode = cidCodes.map(x => x.cidCode).sort()

          if (!onlyCidCode) {
            toast.info('CID NÃO ENCONTRADO', 'NÃO ACHEI O CID')
            return
          }

          this.allCidCode = onlyCidCode
         
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          this.allCidCode = null
          toast.info('CID não encontrado', 'AVISO')
        })
    },

    formatDate(event) {
      this.teacher.birthDate = dateFilter(event.currentTarget.value)
    },

    async clearTest() {
      this.waitingListTest = new WaitingListTest()
      this.waitingListTest.result = TestSituation.PENDING.id
      this.localTrainingSelected = null
      this.modalitySelected = null
      this.classe = null
      this.showModalWaitingListCreateTest = false
    },

    clearClasses() {
      this.classe = null
    },

    resetForm() {
      this.criteria = new WaitingListCriteria()
    },

    showAdditionalInformation(waitingList) {
      this.waitinglistAdditionalInformation = null
      this.waitingListSelected = waitingList
      this.getAdditionalInformation(waitingList.id)
      this.showDrawerAdditionalInformation = true
    },

    onCloseDrawer() {
      this.showDrawerAdditionalInformation = false
      this.waitingListSelected = null
      this.waitingListTest = new WaitingListTest()
    },

    getCurrentDate() { return moment().format("DD/MM/YYYY") },

    getAll() {
      loading.push()
      WaitingListRepository.GetAll(this.criteria, this.pagination)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.totalWaintingListChildren = res.data.count
          console.log(res.data.data)

          this.waitinglists = res.data.data.map(waitList => {
            return new WaitingListList(waitList)
          })

          this.pagination = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }

          loading.pop()
          return Promise.resolve()
        })
        .catch((err) => {
          loading.pop()
          toast.error(err, 'ERRO')
        })
    },

    getAllLocalTraining() {
      loading.push()
      LocalTrainingRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.localTrainings = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar locais de treinamento', 'ERRO')
        })
    },

    getAllModality() {
      loading.push()
      ModalityRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.modalities = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar modalidades', 'ERRO')
        })
    },

    getAllClasses() {
      if (!this.waitingListTest.localTrainingId || !this.waitingListTest.modalityId) return

      this.classes = []
      this.onSelectModality()

      loading.push()
      ClassRepository.GetAllByLocalTrainingAndModality(this.waitingListTest.localTrainingId, this.waitingListTest.modalityId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          const hasClass = this.waitingListTest.classId

          if(hasClass) {
            const hasClassId  = this.classes.some(classe => classe.id === this.waitingListTest.classId)

            if(!hasClassId){
              this.waitingListTest.classId = null
              this.classes = []
            }
          }

          this.classes = res.data.data

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar turmas', 'ERRO')
        })
    },

    createTest() {
      loading.push()
      WaitingListTestRepository.Create(this.waitingListTest)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          toast.success('Teste criado com sucesso!', 'SUCESSO')
          this.showDrawerAdditionalInformation = false
          this.showModalWaitingListCreateTest = false

          this.onSelectLocalTraining()
          this.onSelectModality()

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao criar o teste', 'ERRO')
        })
    },

    getAllHistoryByWaitingListId() {
      loading.push()
      WaitingListHistoryCallRepository.GetById(this.waitingListHistoryCall.waitingListId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.dataHistory = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar histórico de mensagens', 'ERRO')
        })
    },

    createHistoryCallText() {
      let message = this.waitingListHistoryCall.callText
      if (message == null || message.trim() == '') {
        toast.info('Preencha o campo de registro telefônico', 'AVISO')
        return
      }

      loading.push()
      this.waitingListHistoryCall.callText = this.waitingListHistoryCall.callText.toUpperCase()
      this.waitingListHistoryCall.messageOwner = this.loggedInUser.name
      WaitingListHistoryCallRepository.Create(this.waitingListHistoryCall)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          toast.success('Contato telefônico registrado com sucesso', 'SUCESSO')
          this.waitingListHistoryCall.callText = ''
          this.getAllHistoryByWaitingListId()
          this.getAll()

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao criar o mensagem', 'ERRO')
        })
    },

    deleteHistory() {
      loading.push()
      WaitingListHistoryCallRepository.Delete(this.waitingListHistoryCall.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.getAllHistoryByWaitingListId()
          this.closeModalDeleteHistory()
          this.getAll()
          toast.success('Registro telefônico excluído com sucesso!', 'EXCLUSÃO')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir histórico', 'ERRO')
        })
    },

    getAllDeficiencyType () {
      loading.push()
      DeficiencyTypeRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.deficienciesType = res.data.data

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao pegar os tipos de deficiência', 'ERRO')
        })
    },

    getDeficiencyId(waitinglistId) {
      loading.push()
      WaitingListDeficiencyRepository.GetById(waitinglistId)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          const deficiency = res.data.data[0]

          if (!deficiency) {
            this.showTextAlert = true
            this.cidCode = 'Não Cadastrado'
            this.deficiencyTypeName = 'Não Cadastrado'
            this.cidDescription = 'Não Cadastrado'
            loading.pop()
            return
          }
          this.showTextAlert = false

          this.cidCode = deficiency.cidCode
          this.deficiencyTypeName = deficiency.deficiencyTypeName
          this.cidDescription = deficiency.cidDescription

          this.getCid(deficiency.cidCode)

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar deficiência', 'ERRO')
        })
    },

    getCid (cidCode) {
      loading.push()
      CidRepository.GetByCidCode(cidCode)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          const objectCid = res.data.data

          this.cidDescription = objectCid.description

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.info('CID não encontrado', 'AVISO')
        })
    },

    onSelectLocalTraining () {
      let list = this.modalityLocalTraining

      if (this.waitingListTest.localTrainingId) {
        const type = this.localTrainings.filter(s => s.id === this.waitingListTest.localTrainingId)

        if (type.length > 0) {
          this.waitingListTest.localTrainingName = type[0].name
          list = this.modalityLocalTraining.filter(s => s.localTrainingId === type[0].id)
        }
      }

      this.modalities = []
      for (var i = 0; i < list.length; i++) {
        let modality = {
          id: list[i].modalityId,
          name: list[i].modalityName
        }

        if (this.modalities.filter(m => m.id === modality.id).length === 0) {
          this.modalities.push(modality)
        }
      }
    },

    onSelectModality () {
      let list = this.modalityLocalTraining

      if (this.waitingListTest.modalityId) {
        let type = this.modalities.filter(s => s.id === this.waitingListTest.modalityId)

        if (type.length > 0) {
          this.waitingListTest.modalityName = type[0].name
          list = this.modalityLocalTraining.filter(s => s.modalityId === type[0].id)
        }
      }

      this.localTrainings = []
      for (var i = 0; i < list.length; i++) {
        const local = {
          id: list[i].localTrainingId,
          name: list[i].localTrainingName
        }

        if (this.localTrainings.filter(l => l.id === local.id).length === 0) {
          this.localTrainings.push(local)
        }
      }
    },

    onSelectClasse () {
      if (!this.waitingListTest.classId) return

      const classe = this.classes.find(classe => classe.id === this.waitingListTest.classId)

      if (!classe) return

      this.waitingListTest.className = classe.name
    },

    loadModalityLocalTraining () {
      this.modalityLocalTraining = []
      loading.push()
      ModalityLocalTrainingRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.modalityLocalTraining = res.data.data
          this.onSelectModality()
          this.onSelectLocalTraining()

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Não foi possível carregar a lista de Modalidades e Locais de treinamento.', 'ERRO')
        })
    }
  }
}
