import SocialProjectManager from '../../clients/abdaSocialProjectManager'

const resourceName = 'waitinglisthistorycall'

const GetById = waitinglistid => SocialProjectManager.get(`${resourceName}/${waitinglistid}`)

const Create = data => SocialProjectManager.post(resourceName, data)

const Delete = id => SocialProjectManager.delete(`${resourceName}/${id}`)

const WaitingListHistoryCallRepository = {
  GetById,
  Create,
  Delete
}

export default WaitingListHistoryCallRepository