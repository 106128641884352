import GuidUtils from '@/shared/utils/guid'

export default class WaitingListList {
  constructor({
    id,
    name,
    responsible,
    schoolTerm,
    birthDate,
    dateLastContactTest,
    contact,
    cpf,
    rg,
    historyCall,
    hasDeficiency,
    cid,
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.name = name,
    this.responsible = responsible,
    this.schoolTerm = schoolTerm,
    this.birthDate = birthDate,
    this.dateLastContactTest = dateLastContactTest,
    this.contact = contact,
    this.cpf = cpf,
    this.rg = rg,
    this.historyCall = historyCall,
    this.hasDeficiency = hasDeficiency,
    this.cid = cid
  }
}