import GuidUtils from '@/shared/utils/guid'

export default class WaitingListHistoryCall {
  constructor({
    id,
    waitingListId,
    callText,
    messageOwner,
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.waitingListId = waitingListId,
    this.callText = callText,
    this.messageOwner = messageOwner
  }
}
